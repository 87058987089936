import React from 'react';
import { Container, Grid } from '@material-ui/core';


function Home(){
    return(
        <Container>            
            <Grid container alignContent="center" justify="center">
                <img alt="logo" width="70%" src={require('./imagens/logofull.png')} style={{marginTop: 100}} />
            </Grid>
            <Grid id="textohome1">
                <span>Cardápio online com pedidos via Whatsapp</span>
            </Grid>
            <Grid id="textohome2">
                <span>Um canal de vendas para delivery e retirada, <strong>sem intermediários.</strong></span>
            </Grid>
            <Grid id="textohome3">
                <span>Tudo direto com seu cliente</span>
            </Grid>
        </Container>
    )
}

export default Home;