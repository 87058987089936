import React, { useState, useEffect } from 'react';
import './App.css';
import { Row, Col, Container } from 'reactstrap';
import { InputLabel, Select, MenuItem, TextField, Checkbox, Box, RadioGroup, FormControl, FormControlLabel, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Snackbar, CircularProgress, Backdrop, Divider, Paper, Button, Grid, Typography, Fab, Badge, Radio } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from "firebase/app";

// Add the Firebase products that you want to use
import "firebase/firestore";

import StoreIcon from '@material-ui/icons/Store';
import PhoneIcon from '@material-ui/icons/Phone';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

import moment from 'moment';
require('moment/locale/pt-br');

//Configura acesso ao firebase
const firebaseConfig = {
  apiKey: "AIzaSyCTK8ovfFK6ZZo2hUOFElfW2wgIzNWNGic",
  authDomain: "pj-cardapio.firebaseapp.com",
  databaseURL: "https://pj-cardapio.firebaseio.com",
  projectId: "pj-cardapio",
  storageBucket: "pj-cardapio.appspot.com",
  messagingSenderId: "931432345002",
  appId: "1:931432345002:web:1ee3a8c44ac58339428fd3",
  measurementId: "G-C7T42XQ0TG"
};

firebase.initializeApp(firebaseConfig);

const telaAltura = window.innerHeight;
let telaLargura = window.innerWidth;
let posicaoCardapio = 0;
let compLargura = true;
if(telaLargura > 740){
  posicaoCardapio = (telaLargura/2) - (740/2);
  telaLargura = 740;
  compLargura = false;
}

//alert('l:'+telaLargura+'  a:'+telaAltura);

//cria base de dados para nova loja
async function CriaNovaLoja(){
  /*
  await firebase.firestore().doc('lojas/sanburguer').set({
    nome: 'SAN BURGER',
    cor_primaria: '#666',
    localizacao: new firebase.firestore.GeoPoint(-22.750558,-47.424781),
    favicon: '',
    segmento: 'hamburgueria',
    logo: 'https://firebasestorage.googleapis.com/v0/b/pj-cardapio.appspot.com/o/lojas%2Fsanburguer%2Flogo%2Fsanburguer_logo.png?alt=media&token=3c00f8ed-b309-4717-80f3-1f7349bdb5a6',
    background: '',
    status: true,
    telefone_celular: '19 99556-1832',
    telefone_fixo: '19 3628-1746',
    whatsapp: '19 99556-1832',
    endereco: {
      rua: 'Avenida Tiradentes',
      cep: '13450-236',
      numero: '1398',
      cidade: "Santa Bárbara D'Oeste",
      estado: 'São Paulo'
    }
  });  */

/*
  await firebase.firestore().doc('lojas/lanchonetes/categorias/010').set({
    posicao: 10,
    nome: 'Bebidas',
    descricao: '',
    subcategoria: '',
    imagem: '',
    ativa: true
  }); */

  /*
  //Teste importante para criação de produtos e complementos
  let teste = [
    {
      categoria: 'Pizzas',
      codigo: '002',
      descricao: 'Molho, Musarela, Atum, Azeitonas, Orégano e Tomate',
      imagem: '',
      meia_porcao: true,
      nome: 'Atum com Mussarela',
      preco: 0,
      qtde: 0,
      status: true,
      observacoes: '',
      grupos_complementos: [
        {
          complementos: [
            {
              descricao: 'Chocolate',
              selefionado: false,
              valor: 1
            }
          ],
          descricao: 'Adinionais',
          maximo: 1,
          obrigatorio: false
        },
      ],
      tamanhos: null
    }
  ];

  let comp = [
    {
      descricao: 'Chocolate2',
      selefionado: false,
      valor: 1
    },
    {
      descricao: 'Chocolate3',
      selefionado: false,
      valor: 1
    },    
]
  teste[0].grupos_complementos[0].complementos.push(comp[0]);
  teste[0].grupos_complementos[0].complementos.push(comp[1]);

console.log('variavel teste',teste);
//await firebase.firestore().doc('lojas/lanchonetes/produtos/111').set(teste[0]);
*/
/*
  //modelo para produto pizza
  await firebase.firestore().doc('lojas/pizzarias/produtos/002').set({
    categoria: 'Pizzas',
    codigo: '002',
    descricao: 'Molho, Musarela, Atum, Azeitonas, Orégano e Tomate',
    imagem: '',
    meia_porcao: true,
    nome: 'Atum com Mussarela',
    preco: 0,
    qtde: 0,
    status: true,
    observacoes: '',
    grupos_complementos: [
      {
        complementos: [
          {
            descricao: 'Chocolate',
            selefionado: false,
            valor: 1
          }
        ],
        descricao: 'Adinionais',
        maximo: 1,
        obrigatorio: false
      },
    ],
    tamanhos: null
  }); */
/*
    //modelo para produto Lanches
    await firebase.firestore().doc('lojas/lanchonetes/produtos/007').set({
      categoria: 'Hambúrguer Artesanal',
      codigo: '007',
      descricao: 'Hambúrguer Artesanal, Calabresa, Ovo, Frango Empanado, Queijo Prato, Presunto, Bacon, Catupiry, Alface ou Rúcula.',
      imagem: '',
      meia_porcao: false,
      nome: 'X-Tudo',
      preco: 24,
      qtde: 0,
      status: true,
      observacoes: '',
      grupos_complementos: [
        {
          descricao: 'Adinionais',
          maximo: 3,
          obrigatorio: false,
          complementos: [
            {
              descricao: 'Ovo',
              selecionado: false,
              valor: 1
            },
            {
              descricao: 'Bacon',
              selecionado: false,
              valor: 3
            },
            {
              descricao: 'Cheddar',
              selecionado: false,
              valor: 4
            },
            {
              descricao: 'Catupiry',
              selecionado: false,
              valor: 4
            },
            {
              descricao: 'Batata Palha',
              selecionado: false,
              valor: 0
            },
            {
              descricao: 'Presunto',
              selecionado: false,
              valor: 1
            },
            {
              descricao: 'Queijo',
              selecionado: false,
              valor: 1
            },
          ]
        },
      ],
      tamanhos: null
    });

  return; */
/*
  await firebase.firestore().doc('lojas/lanchonetes/produtos/020').set({
    codigo: '020',
    categoria: 'Bebidas',
    nome: 'Coca-Cola',
    descricao: "Coca-Cola 2L",
    imagem: '',
    preco: 12.00,
    qtde: 0,
    status: true,
    observacoes: '',
    meia_porcao: false,
    grupos_complementos: null,
    tamanhos: null
  }); */
  /*
  await firebase.firestore().doc('lojas/pizzarias/produtos/001/tamanhos/Broto').set({
    preco: 25,
    tipos: ['Inteira']
  });*/

/*
  
  await firebase.firestore().doc('lojas/sanburguer/horarios/0').set({
    dia: 'Domingo',
    inicio: '11:00',
    fim: '23:30'
  });
  await firebase.firestore().doc('lojas/sanburguer/horarios/1').set({
    dia: 'Segunda',
    inicio: '00:00',
    fim: '00:00'
  });
  await firebase.firestore().doc('lojas/sanburguer/horarios/2').set({
    dia: 'Terça',
    inicio: '11:00',
    fim: '23:30'
  });
  await firebase.firestore().doc('lojas/sanburguer/horarios/3').set({
    dia: 'Quarta',
    inicio: '11:00',
    fim: '23:30'
  });
  await firebase.firestore().doc('lojas/sanburguer/horarios/4').set({
    dia: 'Quinta',
    inicio: '11:00',
    fim: '23:30'
  });
  await firebase.firestore().doc('lojas/sanburguer/horarios/5').set({
    dia: 'Sexta',
    inicio: '11:00',
    fim: '23:30'
  });
  await firebase.firestore().doc('lojas/sanburguer/horarios/6').set({
    dia: 'Sábado',
    inicio: '11:00',
    fim: '23:30'
  }); */

  /*
  //codigo para copiar categorias
  firebase.firestore().collection('lojas').doc('sanburguer').collection('produtos') .get().then((res)=>{
    console.log(res.docs);
    res.docs.forEach(r=>{
      firebase.firestore().collection('lojas').doc('sanburger').collection('produtos').doc().set(r.data());
    })
  }) */ 

  //Cria categoria pedido e adiciona 1 pedido
  const x = await firebase.firestore().collection('lojas').doc('lanchonetes').collection('pedidos').add({
    npedido: 1,
    valor: 1.1,
    forma_pagto: '',
    troco: '',
    data_hora: new Date(),
    cliente: '',
    telefone: '',
    tipo: '',
  });

  console.log(x);

  await firebase.firestore().collection('lojas').doc('lanchonetes').collection('detalhesdospedidos').doc(x.id).set({
    itens_pedido: [
      {
        codigo: '',
        Qtde: 0,
        nome: '',
        preco: 0,
        complementos: [
          {
            descricao: '',
            valor: 0
          }
        ]
      }
    ],
    dados_cliente: {
      nome: '',
      telefone: '',
      observacoes: '',
      tipo_pedido: '',
      cep: '',
      endereco: '',
      numero: '',
      complemento: '',
      bairro: '',
      ponto_referencia: ''
    }
  });
return;
  await firebase.firestore().collection('lojas').doc('lanchonetes').collection('pedidos').doc('8ok4RzaUmLGREZF2nSbj').set({
    npedido: 1,
    valor: 1.1,
    forma_pagto: '',
    troco: '',
    data_hora: new Date(),
    taxa_entrega: 0,
    itens_pedido: [
      {
        codigo: '',
        Qtde: 0,
        nome: '',
        preco: 0,
        complementos: [
          {
            descricao: '',
            valor: 0
          }
        ]
      }
    ],
    dados_cliente: {
      nome: '',
      telefone: '',
      observacoes: '',
      tipo_pedido: '',
      cep: '',
      endereco: '',
      numero: '',
      complemento: '',
      bairro: '',
      ponto_referencia: ''
    }
  })
}

//CriaNovaLoja();


let dados = [];
let catdados = [];
let complemento = []; //variavel de complemento global
let lojaAberta = false;

//sessionStorage.clear();

function App() {

  //Pega localização
  const localizacao = () => {
    navigator.geolocation.getCurrentPosition(function(position) {
      console.log("Latitude is :", position.coords.latitude);
      console.log("Longitude is :", position.coords.longitude);
    },err => console.log('loc:',err.message));
  }

  useEffect(()=>{

    //define a imagem de fundo da pagina somente quando entrar no cardápio
    const imgFundo = require('./imagens/fundo2.jpg');
    document.getElementById('tagbody').style.backgroundImage = "url("+imgFundo+")";

    //Verifica se loja existe com base na url
    async function lojaExiste(){
      let loja = '';
      const dominioProprio = (sessionStorage.getItem("dominioProprio") === 'true');
      
      if(dominioProprio){
        const dominio = sessionStorage.getItem("dominio");
        const respLoja = await firebase.firestore().collection('dominios').where('dominio', '==', dominio).get();
        loja = respLoja.docs[0].data().loja;
      }else{
        loja = window.location.pathname.substring(1,window.location.pathname.length);
      }
      sessionStorage.setItem("loja", window.location.href);
      await firebase.firestore().collection('lojas').doc(loja).get().then(async(res)=>{
        if(res.exists){          
          const dadosLoja = res.data();          
          if(!dadosLoja.status){
            window.location.href = "/";              
          }
          setProgresso({valor:20, msg: 'Carregando dados da loja'});          
          sessionStorage.setItem('dadosLoja', JSON.stringify(dadosLoja));
          document.title = dadosLoja.nome;

          await firebase.firestore().collection('lojas').doc(loja).collection('horarios').orderBy(firebase.firestore.FieldPath.documentId(), 'asc').get()
          .then((res)=>{
            setProgresso({valor:40, msg: 'Carregando dados da loja'});

            let horariosTemp = [];
            res.forEach((hr)=>{
              horariosTemp.push(hr.data());
            })
            sessionStorage.setItem('horariosLoja', JSON.stringify(horariosTemp));
            //Define se loja esta aberta ou fechada
            const diaSemana = moment().format('dddd').split('-');
            horariosTemp.forEach(dia => {
              if(diaSemana[0].toLocaleLowerCase() === dia.dia.toLocaleLowerCase()){
                const hoje = new Date();
                const diah = hoje.getDate();
                const mesh = hoje.getMonth()+1;
                const anoh = hoje.getFullYear();

                const inicio = new Date(anoh+'/'+mesh+'/'+diah+' '+dia.inicio);
                const fim = new Date(anoh+'/'+mesh+'/'+diah+' '+dia.fim);

                if(hoje.getTime() > inicio.getTime() && hoje.getTime() < fim.getTime()){
                  lojaAberta = true;
                }
              }
            })
          })
          await firebase.firestore().collection('lojas').doc(loja).collection('cartoes').orderBy('tipo', 'asc').get()
          .then((res)=>{
            setProgresso({valor:60, msg: 'Carregando dados da loja'});

            let cartoesTemp = [];
            res.forEach((cartoes)=>{
              cartoesTemp.push(cartoes.data());
            })
            sessionStorage.setItem('cartoesLoja', JSON.stringify(cartoesTemp));
          })


          let produtosTemp = [];
          let categoriasTemp = [];
          let progresso = 60;
          let dSemana = moment().format('dddd').split('-');
          await firebase.firestore().collection('lojas').doc(loja).collection('categorias').where('ativa', '==', true).where('dia_semana', 'array-contains', dSemana[0].toLocaleLowerCase()).orderBy('posicao', 'asc').get()
          .then(async(cat)=>{
              let progressoSoma = 40 / cat.size;
              for(let c of cat.docs){  
                const { nome } = c.data();
                categoriasTemp.push(nome);
                const produto = await firebase.firestore().collection('lojas').doc(loja).collection('produtos').where('categoria', '==', nome).where('status', '==', true).where('dia_semana', 'array-contains', dSemana[0].toLocaleLowerCase()).orderBy('codigo', 'asc').get();
                produto.docs.forEach((prods)=>{
                    const {codigo, categoria, nome, descricao, qtde, preco, imagem, grupos_complementos, tamanhos, status, meia_porcao, sabor_meia, observacoes } = prods.data();
                    produtosTemp.push({codigo, categoria, nome, descricao, qtde, preco, imagem, grupos_complementos, tamanhos, status, meia_porcao, sabor_meia, observacoes });
                })
                progresso = progresso + progressoSoma;
                setProgresso({valor: progresso, msg: 'Carregando produtos'});
            }            
          }).finally(()=>{
            //aguarda o progresso chegar a 100% e carrega a pagina
            setProgresso({valor: 100, msg: 'Cardapio montado!!'});
            setTimeout(() => {
              sessionStorage.setItem("catdados", JSON.stringify(categoriasTemp));
              sessionStorage.setItem("Produtos", JSON.stringify(produtosTemp));
              setCategorias(categoriasTemp);              
              setProdutos(produtosTemp);
              if(!lojaAberta){
                setJanelaHrFunc(true);
              }
            }, 250);
          })
          
        }else{
          window.location.href = "/";
        }
      })
    }
    if(!carrinho){
      lojaExiste();
    }
  },[])

  let carrinho_qtde = 0;
  let classeBackground = '';
  let carrinho = JSON.parse(sessionStorage.getItem('carrinho'));
  const dadosloja = JSON.parse(sessionStorage.getItem('dadosLoja'));
  const horariosloja = JSON.parse(sessionStorage.getItem('horariosLoja'));


    //Define se tem background ou só cor
    if(dadosloja){

      //Muda cor da barra de status do Chrome
      var metaThemeColor = document.querySelector("meta[name=theme-color]");
      metaThemeColor.setAttribute("content", dadosloja.cor_primaria);
      
      if(dadosloja.background === ''){
        classeBackground = {backgroundColor: dadosloja.cor_primaria};
      }else{
        classeBackground = {background: "url("+dadosloja.background+")", backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'};
      }
    }
    if(carrinho){    
      lojaAberta = true;
      dados = JSON.parse(sessionStorage.getItem("Produtos"));
      carrinho_qtde = parseInt(sessionStorage.getItem("carrinho_qtde"));
      catdados = JSON.parse(sessionStorage.getItem('catdados'));
  }
  
  
  const [openSnackbar, setOpenSnackBar] = useState({vis: false, msg: ''});
  const [value, setValue] = useState(carrinho_qtde);
  const [categorias, setCategorias] = useState(catdados);
  const [produtos, setProdutos] = useState(dados);
  const [visFoto, setVisFoto] = useState(false);
  const [visComplementos, setVisComplementos] = useState(false);
  const [visMeiaPorcao, setvisMeiaPorcao] = useState({ vis: false, valor: 0, tamanho: ''});
  const [urlFoto, setUrlFoto] = useState();
  const [nomeFoto, setNomeFoto] = useState();
  const [progresso, setProgresso] = useState({valor: 0, msg: 'Carregando dados da loja'});
  const [visModal, setVisModal] = useState({ vis: false, msg: ''});
  const [saborMeia, setSaborMeia] = useState();
  //variaveis utilizadas no complemento
  const [atualizaVisaoTelaComplementos, setAtualizaVisaoTelaComplementos] = useState(false);
  const [nitens, setnitens] = useState(1);
  const [precoComplemento, setPrecoComplemento] = useState(0);
  const [controleMaximoComplementos, setcontroleMaximoComplementos] = useState(0);
  const [janelaHrFunc, setJanelaHrFunc] = useState(false);
  const [openBackDrop, setOpenBackDrop] = useState(false);


  function mascaraNumero(valor){
    let precoFinal = valor.toString();
    precoFinal = precoFinal.replace('.',',');
    if(precoFinal.indexOf(",")>0) {
        if ((precoFinal.indexOf(",")+1) === (precoFinal.length-1)) {
            precoFinal = precoFinal+"0";
        }
    }else{
        if(precoFinal !== '0'){
          precoFinal = precoFinal+",00";
        }else{
          precoFinal = '';
        }
    }

  return  precoFinal;
}

  const visFotoClickOpen = (nome, url) => {
    setUrlFoto(url);
    setNomeFoto(nome);
    setVisFoto(true);
  };

  /*const handleChange = () => {
    setValue(value + 1);
  };*/

  const adicionaCarrinho = ( codItem ) => {
      let newArr = [];
      if(carrinho){
        newArr = carrinho;
      }
      if(newArr.length === 0){
        let prodres = produtos.filter(element => element.codigo === codItem);
        sessionStorage.setItem("prodtemp", JSON.stringify(prodres[0]));
        let prodTemp = []; 
        prodTemp.push(JSON.parse(sessionStorage.getItem("prodtemp")));
    
        prodTemp[0].qtde++;
        sessionStorage.setItem("carrinho", JSON.stringify(prodTemp));
        sessionStorage.setItem("carrinho_qtde", value + 1);
      }else{
        const posItem = newArr.findIndex(element => element.codigo === codItem);
        if(posItem >= 0 ){
          newArr[posItem].qtde++;
          sessionStorage.setItem("carrinho", JSON.stringify(newArr));          
          sessionStorage.setItem("carrinho_qtde", value + 1);
        }else{
          let prodres = produtos.filter(element => element.codigo === codItem);
          sessionStorage.setItem("prodtemp", JSON.stringify(prodres[0]));
          let prodTemp = []; 
          prodTemp.push(JSON.parse(sessionStorage.getItem("prodtemp")));
  
          prodTemp[0].qtde++;
          newArr.push(prodTemp[0]);
          sessionStorage.setItem("carrinho", JSON.stringify(newArr));
          sessionStorage.setItem("carrinho_qtde", value + 1);
        }
      }
      setOpenSnackBar({vis: true, msg: 'Produto adicionado!'});
      setValue(value + 1);
  }

  const removeCarrinho = ( codItem ) => {
      if(carrinho){
        const posItem = carrinho.findIndex(element => element.codigo === codItem);
        if(posItem >= 0){
          if(carrinho[posItem].qtde > 1){
            carrinho[posItem].qtde--;
            sessionStorage.setItem("carrinho", JSON.stringify(carrinho));
            sessionStorage.setItem("carrinho_qtde", value - 1);            
          }else{
            carrinho.splice(posItem, 1);
            sessionStorage.setItem("carrinho", JSON.stringify(carrinho));
            sessionStorage.setItem("carrinho_qtde", value - 1);            
          }
          setOpenSnackBar({vis: true, msg: 'Produto removido!'});
          setValue( value - 1);
        }
      }
  }

  const AbreJanComplementos = ( nome, descricao ) => {
    const prod = produtos.filter(p => p.nome.indexOf(nome) > -1);
    sessionStorage.setItem("janelaComplementos", JSON.stringify(prod[0]));
    complemento = JSON.parse(sessionStorage.getItem("janelaComplementos"));
    setvisMeiaPorcao(false);    
    setnitens(1); 
    setVisComplementos(true);
  }

  function alteraEstadoComplementoTamanho(tamanho, opcao, valor) {
    for(let c=0; c < complemento.tamanhos.length; c++){
      if(complemento.tamanhos[c].descricao === tamanho){
        for(let op=0; op < complemento.tamanhos[c].tamanhos.length; op++){
          if(complemento.tamanhos[c].tamanhos[op].descricao === opcao){
            complemento.tamanhos[c].tamanhos[op].selecionado = true;
          }else{
            complemento.tamanhos[c].tamanhos[op].selecionado = false;
          }
        }
      }else{
        for(let op=0; op < complemento.tamanhos[c].tamanhos.length; op++){
          complemento.tamanhos[c].tamanhos[op].selecionado = false;
          }
      }
    } 

    for(let c=0; c < complemento.grupos_complementos.length; c++){      
      for(let op=0; op < complemento.grupos_complementos[c].complementos.length; op++){
          complemento.grupos_complementos[c].complementos[op].selecionado = false; 
        }          
    }
  


    if(opcao === "Meia"){
      setSaborMeia('');
      setPrecoComplemento(valor/2);
      setvisMeiaPorcao({vis: true, tamanho: tamanho});
    }else{
      setPrecoComplemento(valor);
      setvisMeiaPorcao({vis: false, tamanho: ''});
    }
  }

  function alteraEstadoComplementos( nome, item ) {
    let precoAtualizado = precoComplemento;
    for(let c=0; c < complemento.grupos_complementos.length; c++){      
      if(complemento.grupos_complementos[c].descricao === nome && complemento.grupos_complementos[c].maximo === 1){
        for(let op=0; op < complemento.grupos_complementos[c].complementos.length; op++){
          if(complemento.grupos_complementos[c].complementos[op].descricao === item){
            //se for false, altera o estado para true, senão, não altera nada
            if(complemento.grupos_complementos[c].complementos[op].selecionado === false){
              complemento.grupos_complementos[c].complementos[op].selecionado = true; 
              precoAtualizado = precoAtualizado + complemento.grupos_complementos[c].complementos[op].valor;
            }
          }else{
              if(complemento.grupos_complementos[c].complementos[op].selecionado === true){
                precoAtualizado = precoAtualizado - complemento.grupos_complementos[c].complementos[op].valor;
              }
            complemento.grupos_complementos[c].complementos[op].selecionado = false; 
          }          
      }
      precoComplemento !== precoAtualizado ? setPrecoComplemento(precoAtualizado) : setAtualizaVisaoTelaComplementos(!atualizaVisaoTelaComplementos)
      return;
    }
      
      if(complemento.grupos_complementos[c].descricao === nome && complemento.grupos_complementos[c].maximo > 1){

        let tabelasDeComplementos = document.querySelectorAll(`form[name="${nome}"]`);
        let contaOpcoes = 0;
        
        for(let op=0; op < tabelasDeComplementos[0].length; op++){
          if(tabelasDeComplementos[0][op].checked){
            contaOpcoes++;
          }
        }
        if(contaOpcoes > complemento.grupos_complementos[c].maximo){
          setVisModal({vis:true, msg: 'Você pode selecionar no máximo ' + complemento.grupos_complementos[c].maximo + ' itens.'});
          setTimeout(() => {
            setVisModal({vis:false});
          }, 2000);
          return;
        }


          for(let op=0; op < complemento.grupos_complementos[c].complementos.length; op++){
          if(complemento.grupos_complementos[c].complementos[op].descricao === item){
            if(complemento.grupos_complementos[c].complementos[op].selecionado){
              complemento.grupos_complementos[c].complementos[op].selecionado = false;
              if(complemento.grupos_complementos[c].complementos[op].valor !== 0) {
                setPrecoComplemento( (precoComplemento*10 - complemento.grupos_complementos[c].complementos[op].valor*10)/10 );
              }else{
                setAtualizaVisaoTelaComplementos(!atualizaVisaoTelaComplementos);
              }
            }else{
              if(controleMaximoComplementos + 1 > complemento.grupos_complementos[c].maximo){
                setVisModal({vis:true, msg: 'Você pode selecionar no máximo ' + complemento.grupos_complementos[c].maximo + ' itens.'});
                setTimeout(() => {
                  setVisModal({vis:false});
                }, 2000);
                return;
              }    
              complemento.grupos_complementos[c].complementos[op].selecionado = true; 
              if(complemento.grupos_complementos[c].complementos[op].valor !== 0) {
                setPrecoComplemento( (precoComplemento*10 + complemento.grupos_complementos[c].complementos[op].valor*10)/10 );
              }else{
                setAtualizaVisaoTelaComplementos(!atualizaVisaoTelaComplementos);
              } 
            }
          }
        }        
      }
    }
  }

  function verificaChecked( params ){
    let sessao = JSON.parse(sessionStorage.getItem('sessaoComplementoTamanhos')) ;
        
    for(let c=0; c < sessao.length; c++){
      if(sessao[c].opcao === params){
        return sessao[c].valor;
      }
    }
  }

  function gravaMeiaPorcao(params) {
    let newParams = params.split("-");
    complemento.sabor_meia.sabor = newParams[0];
    let valorAntigo = complemento.sabor_meia.valor; 
    complemento.sabor_meia.valor = parseFloat(newParams[1]); 
    let sub = precoComplemento - valorAntigo;
    setPrecoComplemento( sub + parseFloat(newParams[1]));
    setSaborMeia(params);
  }

  function ComplementoTabela( props ) {
    let linha = [];
    let tamanho = [];
    let grp_complementos = [];    
    let c = 0;
    let meiaPorcao = [];
    
    if(complemento.tamanhos){
      if(visMeiaPorcao.vis){
        const produtosMeiaPorcao = produtos.filter(p => p.meia_porcao === true);
        let ArrayProdutosMeiaPorcao = [];
        for(let cp=0; cp < produtosMeiaPorcao.length; cp++){ //varre todos os produtos
          if(produtosMeiaPorcao[cp].nome !== complemento.nome){ //se nome for diferente da pizza principal, entra
            for(let ct=0; ct < produtosMeiaPorcao[cp].tamanhos.length; ct++){//pesquisa quantos tamanhos cadastrados
              if(produtosMeiaPorcao[cp].tamanhos[ct].descricao === visMeiaPorcao.tamanho){
                for(let t=0; t < produtosMeiaPorcao[cp].tamanhos[ct].tamanhos.length; t++){
                  if(produtosMeiaPorcao[cp].tamanhos[ct].tamanhos[t].descricao === "Meia"){
                    ArrayProdutosMeiaPorcao.push({
                      nome: produtosMeiaPorcao[cp].nome,
                      preco: (produtosMeiaPorcao[cp].tamanhos[ct].valor / 2)
                    });
                  }
                }
              }
            }
          }
        }

      meiaPorcao.push(
        <div>
          <FormControl style={{width: '98%', marginTop: 20}}>
            <InputLabel id="select-meia">Escolha a segunda metade</InputLabel>
            <Select id="meia-porcao" labelId="select-meia" value={saborMeia} onChange={(r)=> gravaMeiaPorcao(r.target.value)}>
              { ArrayProdutosMeiaPorcao.map(p=> <MenuItem value={p.nome+"-"+p.preco}>{p.nome} - R$ {p.preco}</MenuItem>) }
              <MenuItem value={"Marguerita-10"}>Marguerita</MenuItem>
            </Select>
          </FormControl>
        </div>);
      }
      complemento.tamanhos.forEach(r => {
        linha.push(
          <TableRow id="comp-linha">
          <TableCell component="th" >{r.descricao}</TableCell>
          <TableCell padding="none" component="th">{mascaraNumero(r.valor)}</TableCell>
          { r.tamanhos.map((rt) => c++%2 === 0 ? <TableCell padding="none" style={{paddingLeft: 12}} component="th">
            <FormControlLabel control={<Radio checked={rt.selecionado} value={r.descricao+rt.descricao} id="tam" onChange={()=>alteraEstadoComplementoTamanho(r.descricao,rt.descricao, r.valor)} />} /></TableCell> : 
            <TableCell padding="none" style={{paddingLeft: 12}} component="th">
              <FormControlLabel control={<Radio checked={rt.selecionado} value={r.descricao+rt.descricao} id="tam" onChange={()=>alteraEstadoComplementoTamanho(r.descricao,rt.descricao, r.valor)}/>} /></TableCell>) }
        </TableRow>
        );
      });

      tamanho.push(
        <FormControl>
        <input type="hidden" id="tabTamanho" />
        <RadioGroup>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell id="tabelaT">Escolher tamanho</TableCell>                        
                  <TableCell style={{paddingRight: 60}} id="tabelaT"></TableCell>
                  <TableCell padding="none" id="tabelaT">Inteira</TableCell>
                  <TableCell padding="none" id="tabelaT">Meia</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                { linha.map(r => r) }
              </TableBody>
            </Table>
          </TableContainer>
        </RadioGroup>
      </FormControl>
      );
    }

    if(complemento.grupos_complementos){
      
      complemento.grupos_complementos.forEach(r=>{
        if(r.complementos !== null){
          grp_complementos.push(
            <TableContainer style={{marginTop: 10}}>
            <form id="complementos" complemento={r.descricao} name={r.descricao} obrigatorio={r.obrigatorio ? 'sim' : 'nao'} maximo={r.maximo}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={3} align="left">{r.descricao} (Max: {r.maximo}) - {r.obrigatorio ? 'Obrigatório' : 'Opcional'}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  { r.complementos.map(function(r2){
                      let arrayItemComplemento = [];
                      if(r2.ativo){
                        arrayItemComplemento.push(
                          <TableRow key={r2.descricao}>
                          <TableCell padding="none" style={{paddingLeft: 12}}>{r2.descricao}</TableCell>
                          <TableCell align="right" padding="none" style={{paddingRight: 0}}>{ r2.valor !== 0 ? '+R$ '+mascaraNumero(r2.valor) : ''}</TableCell>
                          <TableCell align="center" padding="none">{r.maximo === 1 ? <FormControlLabel id="radioteste" value={r2.descricao} control={<Radio checked={r2.selecionado} onClick={()=> alteraEstadoComplementos(r.descricao, r2.descricao) } />} /> : <FormControlLabel value={r2.descricao} control={<Checkbox checked={r2.selecionado}/>} onClick={()=> alteraEstadoComplementos(r.descricao, r2.descricao) }/>}</TableCell>
                        </TableRow>    
                        );
                      }
                      return arrayItemComplemento;
                    }
                  )}
                </TableBody>
              </Table>
            </form>
          </TableContainer>
          );
        }
      })
    }

      return (
        <div>
        { tamanho ? tamanho.map(r=>r) : ''}
        { visMeiaPorcao.vis ? meiaPorcao.map(r=>r) : '' }
      { grp_complementos ? grp_complementos.map((r, i)=>(<RadioGroup key={i}>{r}</RadioGroup>)) : '' }
        </div>
      )    
  }


  function gravaComplementos(){
    let contaOpcoes = 0;

    //verifica se tem tabela tamnhos - mais utilizados para pizzas
    let tabelaTamanho = document.querySelectorAll('input[id="tabTamanho"]');
    if(tabelaTamanho.length > 0){
      let tamOpcao = document.querySelectorAll('input[id="tam"]');

      for(let c=0; c < tamOpcao.length; c++){
        if(tamOpcao[c].checked){
          contaOpcoes++;
        }
      }

      if(contaOpcoes === 0){
        setVisModal({vis:true, msg: 'Por favor, escolha um tamanho'});
        setTimeout(() => {
          setVisModal({vis:false});
        }, 2000);
        return;
      }

        let selectMeia = document.querySelectorAll('div[id="meia-porcao"]');
        if(selectMeia.length > 0){
          if(selectMeia[0].innerText.length <= 1){
            setVisModal({vis: true, msg: 'Por favor, escolha a outra metade da sua pizza'});
            setTimeout(() => {
              setVisModal({vis:false});
            }, 2000);
            return;
          }
        }
      
    }

    contaOpcoes = 0;

    //verifica se complementos obrigatórios foram selecionados
    let tabelasDeComplementos = document.querySelectorAll('form[id="complementos"]');
    
    if(tabelasDeComplementos.length > 0){
      for(let c=0; c < tabelasDeComplementos.length; c++){
        let obrigatorio = tabelasDeComplementos[c].attributes.obrigatorio.value;
        if(obrigatorio === "sim"){
          for(let op=0; op < tabelasDeComplementos[c].length; op++){
            if(tabelasDeComplementos[c][op].checked){
              contaOpcoes++;
            }
          }
          if(contaOpcoes === 0){
            setVisModal({vis:true, msg: 'Por favor, escolha os complementos obrigatórios'});
            setTimeout(() => {
              setVisModal({vis:false});
            }, 2000);
            return;
          }
        }
      }
    }

    complemento.qtde = nitens;
    complemento.observacoes = document.getElementById("observacoes").value;
    
    if(carrinho){
      carrinho.push(complemento);
      sessionStorage.setItem("carrinho", JSON.stringify(carrinho));
    }else{
      let newArr = [];
      newArr.push(complemento);
      sessionStorage.setItem("carrinho", JSON.stringify(newArr));
    }
    sessionStorage.setItem("carrinho_qtde", value + nitens);
    setValue(value + nitens);
    setVisComplementos(false);
    setOpenSnackBar({vis: true, msg: 'Produto adicionado!'});
  }

  const verCarrinho = () => {
    if(value !== 0){
      //setOpenBackDrop(true);
      const loja = window.location.pathname.substring(1,window.location.pathname.length);
      //verifica se usa o dominio pedezazzzz.com.br ou dominio proprio do cliente. Se pedezazzz, colocar nome da loja, senão, não.
      loja !== "" ? window.location.href = `/${loja}/checkout` : window.location.href = `/checkout`;
    }else{
      setOpenSnackBar({vis: true, msg: 'Sem produto na cesta.'});
    }
  }

  function pesquisaQtdeProdCarrinho(params) {
    if(carrinho){
      let posItem = carrinho.findIndex(element => element.codigo === params);
      if(posItem !== -1){
        return carrinho[posItem].qtde;
      }else{
        return 0;
      }  
    }else{
      return 0;
    }
    
  }

  function Products(props){
    let Mprodutos = [];
    let prodIndice = 0;
    let categoria = props.categoria;

    props.produtos.forEach((prods)=>{
      if(prods.categoria === categoria){
        let precoFinal = prods.preco.toString();
        precoFinal = precoFinal.replace('.',',');
        
        if(precoFinal.indexOf(",")>0) {
            if ((precoFinal.indexOf(",")+1) === (precoFinal.length-1)) {
                precoFinal = precoFinal+"0";
            }
        }else{
            precoFinal = precoFinal+",00";
        }
        if(prods.grupos_complementos || prods.tamanhos){
          Mprodutos.push(
            <Grid container justify="space-between" key={prods.nome} >
            {prodIndice > 0 ? <Divider style={{marginLeft: 10, marginRight: 20, marginTop: 20, marginBottom: 20, width: '98%'}} /> : ''}
            <Grid container alignItems="center" item xs={8} >
              <Grid>
                <Typography id="titProduto">{prods.nome.toUpperCase()}</Typography>
                <Typography id="descProduto">{prods.descricao}</Typography>
                <Typography id="textoPrecoProdutoAPartir">{ precoFinal !== '0,00' ? `A partir de R$ ${precoFinal}` : ''}</Typography>
              </Grid>
            </Grid>
            <Grid container item xs={4} direction="column" className="qtdeProduto" >
              <Grid item style={{textAlign: 'center'}}>
                { prods.imagem ? <img style={{marginBottom: 10}} onClick={()=>visFotoClickOpen(prods.nome, prods.imagem)} alt={prods.nome} id="imgProduto" src={prods.imagem} /> : '' }
                <Grid container direction="row" justify="center" alignItems="center">
                  { lojaAberta ? <Button size="small" variant="contained" onClick={()=>{ AbreJanComplementos(prods.nome, prods.descricao); setPrecoComplemento(prods.preco);}}>Escolher</Button> : <Button variant="contained" onClick={()=> setJanelaHrFunc(true) }>Escolher</Button> }                  
                </Grid>
              </Grid>
            </Grid>
          </Grid>          
          )
        }else{
          Mprodutos.push(
            <Grid container justify="space-between" key={prods.nome} >
            {prodIndice > 0 ? <Divider style={{marginLeft: 10, marginRight: 20, marginTop: 20, marginBottom: 20, width: '98%'}} /> : ''}
            <Grid container alignItems="center" item xs={8} >
              <Grid>
                <Typography id="titProduto">{prods.nome.toUpperCase()}</Typography>
                <Typography id="descProduto">{prods.descricao}</Typography>
              </Grid>
            </Grid>
            <Grid container item xs={4} direction="column" className="qtdeProduto" >
              <Grid item style={{textAlign: 'center', marginRight: 17}}>
                { prods.imagem ? <img onClick={()=>visFotoClickOpen(prods.nome, prods.imagem)} alt={prods.nome} id="imgProduto" src={prods.imagem} /> : '' }
                <Typography id="textoQtdeProduto">R$ {precoFinal}</Typography>
                <Grid container direction="row" justify="center" alignItems="center">
                  <img alt="Retirar produto" src={require('./imagens/menos.png')} style={{height: 20, width: 20, cursor: 'pointer'}} onClick={()=>removeCarrinho(prods.codigo)} />
                  <Typography style={{paddingLeft: 10, paddingRight: 10}}>{pesquisaQtdeProdCarrinho(prods.codigo)}</Typography>
                  { lojaAberta ? <img alt="Incluir produto" src={require('./imagens/mais.png')} style={{height: 20, width: 20, cursor: 'pointer'}} onClick={()=>adicionaCarrinho(prods.codigo)} /> : <img alt="Incluir produto" src={require('./imagens/mais.png')} style={{height: 20, width: 20, cursor: 'pointer'}} onClick={()=> setJanelaHrFunc(true) } /> }
                </Grid>
              </Grid>
            </Grid>
          </Grid>          
          )
        }
        prodIndice++;
      }      
    })
    return(
      <Row>
        {Mprodutos.map((res)=> res)}
      </Row>
      )
  }

  function ProductCategoryRow(props){
    let catSessao = [];
    props.categorias.forEach((cat)=>{
      catSessao.push(
        <Paper className="corpo sessao" key={cat}>
          <Grid container spacing={2} justify="center">
            <Grid item style={{textAlign: 'center', marginBottom: 20}}>
              <Typography id="categoria"><span>{cat}</span></Typography>
            </Grid>
          </Grid>
          <Products produtos={props.produtos} categoria={cat} />
        </Paper>
      )
    })

    return(
      <Row>
        { catSessao.map((res)=>res)}
      </Row>      
    )
  }

  function FilterableProductTable(props){
    return(
        <ProductCategoryRow produtos={props.produtos} categorias={props.categorias} />
      )
  }

  function Horarios(props){
    let horarios = [];
    let horaIndice = 0;
    props.horarios.forEach((res)=>{
      if(res.inicio !== '00:00'){
        horarios.push(
          <Grid key={res.dia}>
            { horaIndice !== 0 ? <Divider /> : '' }
            <Grid item id="horarioSemana">
              <Grid id="diaSemana">{res.dia}</Grid>
              <Grid id="horaSemana">{res.inicio} - {res.fim}</Grid>
            </Grid>
          </Grid>
        )
        horaIndice++;  
      }
    })
    return (
      <Row>
        { horarios.map((r)=>r) }
      </Row>
    )
  }

  if(produtos.length !== 0){
  return (
    <Container style={{flex: 1, width: telaLargura, marginLeft: posicaoCardapio}}>
      <Row>
        <Col>
        <Dialog open={visModal.vis}>
          <DialogContent>
            <DialogContentText>
              { visModal.msg }
            </DialogContentText>
          </DialogContent>
        </Dialog>
      
        
        <Dialog open={janelaHrFunc} onClose={()=> setJanelaHrFunc(false)}>
          <DialogContent>
            <Container>
              <Grid>
                <Typography id="tituloHeaderHrFunc">Horário de Atendimento</Typography>
              </Grid>
              <hr />
              <Grid>
                <Typography id="textoHrFunc">No momento estamos fechados, mas verifique os horários abaixo para um próximo pedido.<br /><br />Será um prazer atender você!</Typography>
              </Grid>
              <Grid>
              <Horarios horarios={horariosloja} />
              </Grid>
            </Container>
          </DialogContent>
          <DialogActions>
            <Button onClick={()=> setJanelaHrFunc(false)} >OK</Button>
          </DialogActions>
        </Dialog>

      <Dialog open={visComplementos} fullScreen={compLargura} fullWidth scroll="paper" >
        <DialogContent dividers={true} id="dialogConteudo">
          <Box id="boxNomeComplemento">
            <Grid>
              <Grid><Typography id="complementoNomeProduto">{ complemento.nome }</Typography></Grid>
              <Grid><Typography id="complementoDescricaoProduto">{ complemento.descricao }</Typography></Grid>
            </Grid>
          </Box>
            <Grid id="listaComplementos">
              <ComplementoTabela produto={ complemento.nome } />
            </Grid>
            <Grid container justify="center">
              <TextField id="observacoes" label="Observações do pedido" variant="outlined" style={{width: '95%', marginTop: 20}} />
            </Grid>
        </DialogContent>
        <DialogActions>
        <Grid container direction="row" justify="space-between" alignItems="center">
            <Grid><Button color="primary" onClick={()=>{ setVisComplementos(false); }}>Voltar</Button></Grid>
            <Grid id="precoComplemento">
              { precoComplemento !== 0 ? "R$ " + mascaraNumero(precoComplemento * nitens) : "R$ --,--" }
            </Grid>
            <Grid>
            <Grid container direction="row" justify="center" alignItems="center">
            <img alt="Retirar produto" src={require('./imagens/menos.png')} style={{height: 20, width: 20, cursor: 'pointer'}} onClick={()=>{ if(nitens !== 1){ setnitens(nitens-1); } }} />
                <Typography style={{paddingLeft: 10, paddingRight: 10}}>{nitens}</Typography>
              <img alt="Incluir produto" src={require('./imagens/mais.png')} style={{height: 20, width: 20, cursor: 'pointer'}} onClick={()=>setnitens(nitens+1)} />
            </Grid>
            </Grid>
            <Grid><Button color="primary" onClick={()=> gravaComplementos()}>Adicionar</Button></Grid>
          </Grid>
        </DialogActions>
      </Dialog>

        <Dialog open={visFoto}>
          <DialogTitle>
            {nomeFoto}
          </DialogTitle>
          <DialogContent>
          <img alt="logo" height="100%" width="100%" src={urlFoto} />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={()=>setVisFoto(false)}>FECHAR</Button>
          </DialogActions>
        </Dialog>

        <Row id="cabecalho" className="linha_foto" style={classeBackground}>
          <Container className="grupoHeader">
            <Col>
              <Paper className="App-logo">
                <img alt="logo" height="90%" width="90%" src={dadosloja.logo} />
              </Paper>
            </Col>
            <Col className="textoHeader">
              <Grid container justify="space-between" style={{height: 110}}>
                <Grid container direction="row">
                  <Grid ><StoreIcon /></Grid>
                  <Grid style={{marginTop: 3, marginLeft: 5}}>{dadosloja.nome}</Grid>
                </Grid>
                <Grid container direction="row">
                  <Grid><PhoneIcon /></Grid>
                  <Grid style={{marginTop: 3, marginLeft: 5}}>{dadosloja.telefone_fixo}</Grid>
                </Grid>
                <Grid container direction="row">
                  <Grid><PhoneIphoneIcon /></Grid>
                  <Grid style={{marginTop: 3, marginLeft: 5}}>{dadosloja.telefone_celular}</Grid>
                </Grid>
              </Grid>
            </Col>
          </Container>
        </Row>
        { /*<Button onClick={()=>sessionStorage.clear()}>Limpar</Button> */ }
        <Grid id="informacao">
          <Alert severity="info">Para fazer seu pedido, selecione um item do cardápio e adicione a sua cesta. Após suas escolhas, clique no botão azul da cesta para a próxima etapa.</Alert>  
        </Grid>

        <FilterableProductTable produtos={produtos} categorias={categorias} />

        <Paper className="corpo sessao">
          <Grid id="gridHorario">
              <Grid id="horarioHeader">
                <Grid style={{ marginTop: 3}}>
                  <AccessTimeIcon />
                </Grid>
                <Grid style={{paddingLeft: 10}}>
                  <Typography id="tituloHeader">Horário de Atendimento</Typography>
                </Grid>
              </Grid>
              <Horarios horarios={horariosloja} />
          </Grid>
        </Paper>
        <Paper className="corpo rodape">
          <Grid container alignItems="center">
          <img alt="PedeZazzz - O seu cardápio" src={require('./imagens/logo_p.jpg')} id="logoempresa" style={{height: 30}} />
          <Typography>PedeZazzz - Pedidos online via WhatsApp</Typography>
          </Grid>
        </Paper>
        <Badge badgeContent={value} color="error" style={{position: 'fixed', right: 15, bottom: 10}}>
          <Fab color="primary" aria-label="Carrinho" onClick={()=>verCarrinho()}>
            <ShoppingBasketIcon />
          </Fab>
        </Badge>
        <Snackbar          
          style={{width: 200}}
          open={openSnackbar.vis}
          autoHideDuration={3000}
          onClose={()=>setOpenSnackBar(false)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center'}}
          message={openSnackbar.msg} />
        <Backdrop open={false} style={{zIndex: 1, color: '#fff'}} >
          <CircularProgress color="inherit" />
        </Backdrop>
        </Col>
      </Row>
      <Backdrop open={openBackDrop} style={{zIndex: 1, color: '#fff'}}>
          <Typography>Aguarde...</Typography>
        </Backdrop>
      </Container>
    );
  }else{
    return(
          <Backdrop open={true} style={{zIndex: 1, color: '#fff', flex: 1, flexDirection: 'column'}} >
          <CircularProgress color="inherit" variant="static" value={progresso.valor} />
          <br />
          <Typography>{ progresso.msg }</Typography>
        </Backdrop>
        );
  }
}

export default App;

/*
      <Container style={{backgroundColor: 'black'}}>
        <Row>
          <Col style={{textAlign: 'center'}} md="12">
            <img alt="PedeZazzz" height={telaAltura} sizes="(max-width: 1920px) 280px, (max-width: 800px) 100%" src={require('./imagens/hamburgueria.jpg')} />
          </Col>
        </Row>
      <Row>
        <Backdrop open={true} style={{zIndex: 1, color: '#fff', flex: 1, flexDirection: 'column'}} >
          <CircularProgress color="inherit" variant="static" value={progresso.valor} />
          <br />
          <Typography>{ progresso.msg }</Typography>
        </Backdrop>
      </Row>
      </Container>
*/