import React, { useState } from 'react';
import './App.css';
import { Input, Dialog, DialogContent, DialogActions, InputAdornment, OutlinedInput, InputLabel, FormControl, MenuItem, Select, Box, Tabs, Tab, List, ListItem, Divider, Button, TextField, Grid, Typography } from '@material-ui/core';

// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from "firebase/app";

// Add the Firebase products that you want to use
import "firebase/firestore";

import Alert from '@material-ui/lab/Alert';
import MaskedInput from 'react-text-mask';
//import NumberFormat from 'react-number-format';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import IconButton from '@material-ui/core/IconButton';
import ShoppingBasketTwoToneIcon from '@material-ui/icons/ShoppingBasketTwoTone';
import ArrowBackTwoToneIcon from '@material-ui/icons/ArrowBackTwoTone';
import StoreIcon from '@material-ui/icons/Store';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';
import PaymentIcon from '@material-ui/icons/Payment';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { withStyles } from '@material-ui/core/styles'
import moment from 'moment';
require('moment/locale/pt-br');

let vTotal = 0;

let telaLargura = window.innerWidth - 20;
let posicaoCardapio = 0;
if(telaLargura > 740){
  posicaoCardapio = (telaLargura/2) - (740/2);
  telaLargura = 740;
}

let dados = JSON.parse(sessionStorage.getItem("carrinho"));
if(!dados){
  dados = [];
}

const dadosloja = JSON.parse(sessionStorage.getItem('dadosLoja'));
const cartoes = JSON.parse(sessionStorage.getItem('cartoesLoja'));            
function mascaraNumero(valor){
    let precoFinal = valor.toString();
    precoFinal = precoFinal.replace('.',',');
    
    if(precoFinal.indexOf(",")>0) {
        if ((precoFinal.indexOf(",")+1) === (precoFinal.length-1)) {
            precoFinal = precoFinal+"0";
        }
    }else{
        precoFinal = precoFinal+",00";
    }

  return  precoFinal;
}

function mostraPrecos( preco, qtde ){
    let subPreco = preco * qtde;
    vTotal = (vTotal*10 + subPreco*10)/10;
    return mascaraNumero(subPreco);
}

function TabPanel(props) {
    const { children, value, index } = props;
  
    return (
      <div>
        {value === index && (
          <Box p={1}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function TabPanelTab2(props) {
    const { children, value, index } = props;
  
    return (
      <div>
        {value === index && (
          <Box p={1}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const ColorButton = withStyles((theme) => ({
    root: {
      color: '#FFFFFF',
      backgroundColor: '#25d366',
      '&:hover': {
        backgroundColor: '#25d366',        
      },
      padding: 20,
    },
  }))(Button);

  function TextMaskCustom(props) {
    const { inputRef, ...other } = props;

    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={['(', /[1-9]/, /\d/,')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        placeholderChar={'\u2000'}
        showMask={false}
      />
    );
  }  

  function CepMaskCustom(props) {
    const { inputRef, ...other } = props;
    
    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
        placeholderChar={'\u2000'}
        showMask={false}
      />
    );
  }    

/*  function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        isNumericString
        prefix="$"
      />
    );
  }*/  

function Checkout() {
    const [value, setValue] = useState(0);
    const [valueTab2, setValueTab2] = useState(0);
    const [bandeira, setBandeira] = useState('');
    const [valuesMask, setValuesMask] = useState({
        textmask: '',
        numberformat: '1320',
      });
    const [valuesCepMask, setValuesCepMask] = useState({
        cepmask: '',
        numberformat: '1320',
      });      
    const [atualiza, setAtualiza] = useState(false);
    const [carrinho, setCarrinho] = useState(dados);
    const [visMsg, setVisMsg] = useState({ msg: '', aberto: false, indice: 0});
    const [vTotalGeral, setvTotalGeral] = useState(0);

    const handleChangeSelectBandeira = (event) => {
      setBandeira(event.target.value);
      vTotal = 0;
    };

    const handleChange = (event, newValue) => {
        vTotal = 0; //zera valor quando muda de tab
        setValue(newValue);
      };

      const handleChangeTab2 = (event, newValue) => {
        vTotal = 0; //zera valor quando muda de tab
        setValueTab2(newValue);
      };

      const handleChangeMasks = (event) => {
        vTotal = 0; //zera valor quando muda de tab
        setValuesMask({
          ...valuesMask,
          [event.target.name]: event.target.value,
        });
      };

      const handleCepChangeMasks = (event) => {
        vTotal = 0; //zera valor quando muda de tab
        console.log(event.target.name);
        setValuesCepMask({ ...valuesCepMask, [event.target.name]: event.target.value });
      };

      function Cartoes(props) {
        let cartoes = [];
        props.cartoes.forEach((cartao)=>{
          cartoes.push(
            <MenuItem value={cartao.tipo+' - '+cartao.nome}>{cartao.tipo+' - '+cartao.nome}</MenuItem>
          )
        })
        return (
          <Select id="bandeira" value={bandeira} fullWidth label="Bandeira" labelId="rot" onChange={handleChangeSelectBandeira} required>
            {cartoes.map((res)=>res)}
          </Select>
        )
      }

      async function enviaPedido(f) {
        f.persist();
        let tipo = f.target.tipoEntrega.value;
      
        let pedido = []; //array usado para montar itens para whatsapp
        let dadosCliente = []; //array de dados do cliente para base de dados
        let npedido = 0;
        const nomeloja = dadosloja.nome;
        const data = moment(new Date()).format('l');
        const hora = moment(new Date()).format('LT');
        const nomeCliente = f.target.nome.value;
        const celCliente = f.target.maskcelular.value;
        const observacoes = f.target.observacoes.value;

        let endereco = '';
        let numero = '';
        let cep = '';
        let complemento =''; 
        let bairro = '';
        let referencia = '';
        let tipoPagamento = '';
        let troco = '';

        await firebase.firestore().collection('lojas').doc('lanchonetes').collection('pedidos').doc('info_pedidos').update({
          total_pedidos: firebase.firestore.FieldValue.increment(1)
        }).then(async()=>{
          await firebase.firestore().collection('lojas').doc('lanchonetes').collection('pedidos').doc('info_pedidos').get().then((res)=> npedido = res.data().total_pedidos)   ;                 
        })

      
        pedido = `${nomeloja}\n\n${data}, ${hora}\n\nOlá, gostaria de fazer um pedido.\n\nOs itens escolhidos são:\n`;
      
        let valorComplementos = 0;
        let arrayPedido = []; //array usado para gravar dados na base de dados
        let arrayComplementos = [];
        for(let c=0; c < carrinho.length; c++){
          //let listaProdComplementos = [];
          const { nome, preco, qtde, observacoes, codigo } = carrinho[c];
          arrayPedido.push({ nome, preco, qtde, codigo, grupos_complementos: [] });
          pedido = pedido.concat(`*${qtde}x - ${nome}*\n`);
          if(carrinho[c].grupos_complementos){
            carrinho[c].grupos_complementos.forEach( gc => {
              let cab = true; //cabeçalho - nome do grupo do complemento
              gc.complementos.forEach( comp => {
                if(comp.selecionado){
                  if(cab){
                    pedido = pedido.concat(gc.descricao+`\n`);
                    cab= false;
                  }
                  valorComplementos = valorComplementos + comp.valor;
                  pedido = pedido.concat("-"+comp.descricao+`\n`);
                  arrayComplementos.push({ descricao: comp.descricao, valor: comp.valor })
                }
              })
              arrayPedido[c].grupos_complementos.push({ descricao: gc.descricao, complementos: arrayComplementos });
              arrayComplementos = [];
            })
          }
          if(observacoes){
            pedido = pedido.concat(`Observações: ${observacoes}\n`)
          }
          pedido = pedido.concat(`Preço: R$ ${mascaraNumero((preco+valorComplementos)*qtde)}\n\n`);
          valorComplementos = 0;
        }

        if(tipo === 'Retirar'){   
          pedido = pedido.concat(`\n*Total do pedido: R$ ${mascaraNumero(vTotalGeral)}*\n\n`);
          
          if(observacoes){
            pedido = pedido.concat(`*Observações do cliente*\n${observacoes}\n\n`)
          }
      
          pedido = pedido.concat(`---------------------------------------\nRetirada no local\n\nNome: ${nomeCliente}\nCelular: ${celCliente}`)
        }else{
          endereco = f.target.endereco.value;
          numero = f.target.numero.value;
          cep = f.target.cep.value;
          complemento = f.target.complemento.value;
          bairro = f.target.bairro.value;
          referencia = f.target.referencia.value;
      
          tipoPagamento = f.target.tipoPagamento.value;
      
          pedido = pedido.concat(`*Subtotal.: R$ ${mascaraNumero(vTotal)}*\n*Entrega..: R$ Consulte*\n*Total.......: R$ ${mascaraNumero(vTotal)}*\n\n`);
      
          if(observacoes){
            pedido = pedido.concat(`*Observações do cliente*\n${observacoes}\n\n`);
          }
      
          if(tipoPagamento === 'cartao'){
            pedido = pedido.concat(`---------------------------------------\nForma de pagamento: ${bandeira}\n\n`);
          }else{
            troco = f.target.troco.value;
            if(troco){
              pedido = pedido.concat(`---------------------------------------\nForma de pagamento: Dinheiro\nTroco para: R$ ${mascaraNumero(troco)}\n\n`);
            }else{
              pedido = pedido.concat(`---------------------------------------\nForma de pagamento: Dinheiro\nTroco para: sem troco\n\n`);
            }
      
          }
          
      
          pedido = pedido.concat(`*ENTREGA*\n`);
          pedido = pedido.concat(`*Endereço:* ${endereco}, ${numero}\n`);
          if(complemento){
            pedido = pedido.concat(`*Complemento:* ${complemento}\n`);
          }    
          pedido = pedido.concat(`*Cep:* ${cep}\n`);
          pedido = pedido.concat(`*Bairro:* ${bairro}\n`);
          if(referencia){
            pedido = pedido.concat(`*Ponto referencia:* ${referencia}\n`);
          }
          pedido = pedido.concat(`\nNome: ${nomeCliente}\nCelular: ${celCliente}`)
        }

        const iddetalhepedido = await firebase.firestore().collection('lojas').doc('lanchonetes').collection('detalhesdospedidos').add({ itens_pedido: arrayPedido});

        await firebase.firestore().collection('lojas').doc('lanchonetes').collection('detalhesdospedidos').doc(iddetalhepedido.id).update({ 
          dados_cliente: { bairro, cep, complemento, endereco, nome: nomeCliente, numero, observacoes, ponto_referencia: referencia, telefone: celCliente, tipo_pedido: tipo }
        });

        await firebase.firestore().collection('lojas').doc('lanchonetes').collection('pedidos').add({
          cliente: nomeCliente, data_hora: new Date(), forma_pagto: tipoPagamento, npedido, telefone: celCliente, tipo, troco, valor: vTotal, id_detalhes_pedido: iddetalhepedido.id, situacao: 0, cancelado: false
        });
        sessionStorage.clear();
        setCarrinho([]);
        window.location.href = `https://api.whatsapp.com/send?phone=550${dadosloja.whatsapp}&text=${window.encodeURIComponent(pedido)}`;
      }      

      function ExcluirProdutoCesta(params) {
        if(carrinho.length > 1){
          let tempArray = carrinho;
          let nProdutos = sessionStorage.getItem("carrinho_qtde");
          sessionStorage.setItem("carrinho_qtde", nProdutos - tempArray[params].qtde);
          tempArray.splice(params, 1);
          sessionStorage.setItem("carrinho", JSON.stringify(tempArray));
          setCarrinho(tempArray);
          setAtualiza(!atualiza);
        }else{
          setCarrinho([]);
          sessionStorage.removeItem("carrinho");
        }
        setVisMsg({aberto: false});
      }

      function ItensCesta() {
        vTotal = 0;
        let lista = [];
        for(let c=0; c < carrinho.length; c++){
          let valorComplementos = 0;
          let meiasPorcoes = [];
          let listaProdComplementos = [];
          let descricaoProd = '';
          const { nome, preco, qtde, observacoes } = carrinho[c];
          descricaoProd = nome;
          //verifica tamanho caso seja pizza
          if(carrinho[c].tamanhos){
            for(let t=0; t < carrinho[c].tamanhos.length; t++){
              for(let t2=0; t2 < carrinho[c].tamanhos[t].tamanhos.length; t2++){
                if(carrinho[c].tamanhos[t].tamanhos[t2].selecionado){
                  if(carrinho[c].tamanhos[t].tamanhos[t2].descricao !== "Meia"){
                    descricaoProd = `${nome} (${carrinho[c].tamanhos[t].descricao})`;
                    valorComplementos = carrinho[c].tamanhos[t].valor;
                  }else{
                    listaProdComplementos.push(`Meia: ${carrinho[c].nome}`);
                    listaProdComplementos.push(`Meia: ${carrinho[c].sabor_meia.sabor}`);
                    //meiasPorcoes = [`Meia: ${carrinho[c].nome}`,`Meia: ${carrinho[c].sabor_meia.sabor}`];
                    descricaoProd = `${carrinho[c].categoria} ${carrinho[c].tamanhos[t].descricao} (meia a meia)`;
                    valorComplementos = (carrinho[c].tamanhos[t].valor / 2) + carrinho[c].sabor_meia.valor;
                  }
                }
              }
            }
          }


          //Verificar complementos
          if(carrinho[c].grupos_complementos){
            carrinho[c].grupos_complementos.forEach( gc => {
              let cab = true;
              gc.complementos.forEach( comp => {
                if(comp.selecionado){
                  if(cab){
                    listaProdComplementos.push(<strong>{gc.descricao}</strong>);
                    cab= false;
                  }
                  valorComplementos = valorComplementos + comp.valor;
                  listaProdComplementos.push("-"+comp.descricao);
                }
              })
            })
          }
          lista.push(
            <ListItem>
            <Grid item xs="12">
            <hr id="divcor" />
            <Grid container direction="row" justify="space-between" alignItems="center">
                    <Grid item>
                        <Grid container direction="row" id="prodNome"><Typography>{descricaoProd}</Typography><img alt="Retirar produto" src={require('./imagens/excluir.png')} onClick={()=>setVisMsg({msg: 'Deseja realmente apagar o item: '+nome, aberto: true, indice: c})} style={{height: 15, width: 15, marginLeft: 5, marginTop:5, cursor: 'pointer'}} /></Grid>
                        { listaProdComplementos.map( lc => <Grid id="prodQtde">{lc}</Grid> ) }
                        <Grid id="prodQtde">{ observacoes ? `Observações: ${observacoes}` : ''}</Grid>
                        <Grid id="prodQtde">qtde: {qtde}</Grid>
                    </Grid>
                    <Grid item id="prodPreco">R$ {mostraPrecos((preco+valorComplementos), qtde)}</Grid>
                </Grid>       
            </Grid>
            </ListItem>
          )
          //valorComplementos = 0;
        }
        setvTotalGeral(vTotal);
        return (
          <div>
            <List>
            { lista.map(r => r) }  
            </List>
            <Box id="boxValorTotal">
              <Grid container justify="space-between" id="textoValorTotal">
                <Grid>Sub-total</Grid>
                <Grid>R$ {mascaraNumero(vTotalGeral)}</Grid>
              </Grid>
            </Box>
          </div>
        )
      }

      function distance(lat1, lon1, lat2, lon2, unit) {
        var radlat1 = Math.PI * lat1/180
        var radlat2 = Math.PI * lat2/180
        var theta = lon1-lon2
        var radtheta = Math.PI * theta/180
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        dist = Math.acos(dist)
        dist = dist * 180/Math.PI
        dist = dist * 60 * 1.1515
        if (unit=="K") { dist = dist * 1.609344 }
        if (unit=="M") { dist = dist * 0.8684 }
        return dist
     } 

      function buscaCEP(parametro) {
        let cep = parametro.replace('-','');
        let caracter = cep.indexOf('\u2000');
        if(cep.length === 8 && caracter === -1){
          fetch('https://cep.awesomeapi.com.br/json/'+cep).then(res => res.json()).then((jsonData)=> {
            console.log(jsonData);
            if(!jsonData.status){
              let gpsLoja = dadosloja.localizacao;
              let gpsCep = {"latitude": jsonData.lat, "longitude": jsonData.lng};
              let disttmp = distance(gpsLoja.latitude, gpsLoja.longitude, gpsCep.latitude, gpsCep.longitude, 'K');
              let distancia = disttmp.toString().split('.');
              
              
              document.getElementById('endereco').value = jsonData.address;
              document.getElementById('bairro').value = jsonData.district;
              document.getElementById('numero').focus();
            }else{
              document.getElementById('endereco').focus();
            }
          });            
        }
      }

      if(carrinho.length > 0){
                  //define a imagem de fundo da pagina somente quando entrar no cardápio
                  const imgFundo = require('./imagens/fundo2.jpg');
                  document.getElementById('tagbody').style.backgroundImage = "url("+imgFundo+")";
        
        return (
          <>
          <Dialog open={visMsg.aberto}>
            <DialogContent>
              { visMsg.msg }
            </DialogContent>
            <DialogActions>
              <Button onClick={()=> setVisMsg({aberto: false})} >Cancelar</Button>
              <Button onClick={()=> ExcluirProdutoCesta(visMsg.indice)}>Apagar</Button>
            </DialogActions>
          </Dialog>

          <Grid id="corpoPedido" style={{ width: telaLargura, marginLeft: posicaoCardapio}}>
            <Grid container justify="space-between" style={{paddingBottom: 10}}>
              <Grid container style={{width: '50%'}} alignItems="center">
                <Grid>
                  <ShoppingBasketTwoToneIcon style={{marginRight: 10, marginLeft: 10}} />
                </Grid>
                <Grid>
                  <Typography>SUA CESTA</Typography>
                </Grid>
              </Grid>
              <Grid>
                <IconButton onClick={()=>window.history.back()}><ArrowBackTwoToneIcon /></IconButton>
              </Grid>
            </Grid>
            <Divider />
            <Box style={{marginTop: 20, backgroundColor: '#e9edef', borderRadius: 5}}>
              <Box style={{backgroundColor: 'black', color: 'white', width: 134, padding: 5, borderTopLeftRadius: 5}}>
                <Typography>ITENS DO PEDIDO</Typography>
              </Box>
              <ItensCesta />
              </Box>
              <form onSubmit={(f)=>{ enviaPedido(f); f.preventDefault();}}>
              <Grid style={{textAlign: 'center', marginTop: 30, marginBottom: 30}}>
                  <Typography style={{marginTop: 30, marginBottom: 15, textAlign: 'center'}}>Observações</Typography>
                  <TextField id="observacoes" size="small" fullWidth variant="outlined" label="Detalhes do pedido" />
                  <Typography id="tituloSessao">INFORMAÇÕES PARA ENTREGA</Typography>
                  <Grid>
                      <Grid>
                          <TextField id="nome" margin="normal" fullWidth size="small" variant="outlined" InputLabelProps={{ shrink: true, }} label="Nome" required />
                          <FormControl variant="outlined" fullWidth>
                              <InputLabel htmlFor="maskcelular">Celular</InputLabel>
                              <OutlinedInput   
                                  required
                                  margin="dense"                             
                                  value={valuesMask.textmask}
                                  onChange={handleChangeMasks}
                                  name="textmask"
                                  id="maskcelular"
                                  inputComponent={TextMaskCustom}
                                  type="tel"
                                  labelWidth={60}
                                  style={{height: 40}}                                  
                              />
                          </FormControl>
                      </Grid>
                  </Grid>
                  <Tabs value={value} onChange={handleChange} variant="fullWidth">
                      <Tab label="Entrega" icon={<MotorcycleIcon />} />
                      <Tab label="Retirar no local" icon={<StoreIcon />} />
                  </Tabs>
                  <TabPanel value={value} index={0}>
                      <input hidden id="tipoEntrega" value="Entrega" />
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="cep">Cep</InputLabel>
                        <OutlinedInput margin="dense" id="cep" type="text" labelWidth={30} name="cepmask" fullWidth margin="dense" size="small" variant="outlined" inputComponent={CepMaskCustom} value={valuesCepMask.textmask} InputLabelProps={{ shrink: true, }} onChange={(e)=> { handleCepChangeMasks(e); buscaCEP(e.target.value)}} required />
                      </FormControl>
                      <TextField id="endereco" margin="dense"  style={{width: '50%'}} size="small" variant="outlined" InputLabelProps={{ shrink: true, }} label="Endereço" required />
                      <TextField id="numero" type="number" margin="dense" style={{width: '50%'}} size="small" InputLabelProps={{ shrink: true, }} variant="outlined" label="Numero" required />
                      <TextField id="complemento" margin="dense" fullWidth size="small" variant="outlined" InputLabelProps={{ shrink: true, }} label="Complemento (Ex: Bloco/Apto)" />
                      <TextField id="bairro" margin="dense" fullWidth size="small" variant="outlined" InputLabelProps={{ shrink: true, }} label="Bairro" required />
                      <TextField id="referencia" margin="dense" fullWidth size="small" variant="outlined" InputLabelProps={{ shrink: true, }} label="Ponto de referência" />
                      <Alert icon={false} severity="info" style={{flex: 1, justifyContent: 'flex-end', textAlign: 'right'}}>
                        <Grid container >
                          <Grid>
                          Taxa de entrega<br /><span id="valorTaxaEntrega">Consulte</span>
                          </Grid>
                          <Grid style={{marginLeft: 10}}>
                          Total<br /><span id="valorTaxaEntrega">R$ {mascaraNumero(vTotalGeral)}</span>
                          </Grid>                        
                        </Grid>
                      </Alert>
                      <Typography id="tituloSessao">FORMAS DE PAGAMENTO</Typography>
                      <Tabs value={valueTab2} onChange={handleChangeTab2} variant="fullWidth">
                          <Tab label="Maquininha" icon={<PaymentIcon />} />
                          <Tab label="Dinheiro" icon={<AttachMoneyIcon />} />
                      </Tabs>
                      <TabPanelTab2 value={valueTab2} index={0}>
                          <FormControl variant="outlined" fullWidth style={{marginTop: 30}}>
                              <InputLabel id="rot">Bandeira</InputLabel>
                              <input hidden id="tipoPagamento" value="cartao" />
                              <Cartoes cartoes={cartoes} />
                          </FormControl>
                      </TabPanelTab2>
                      <TabPanelTab2 value={valueTab2} index={1}>
                          <FormControl fullWidth variant="outlined" style={{marginTop: 30}}>
                              <InputLabel>Troco para:</InputLabel>
                              <input hidden id="tipoPagamento" value="dinheiro" />
                              <OutlinedInput id="troco" startAdornment={<InputAdornment position="start">R$</InputAdornment>} labelWidth={85} /> 
                        </FormControl>
                      </TabPanelTab2>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                      <Grid style={{marginTop: 30}}>
                          <Grid>
                              <input hidden id="tipoEntrega" value="Retirar" />
                              <Alert severity="info">Total do pedido: <span id="valorTaxaEntrega">R$ {mascaraNumero(vTotalGeral)}</span> </Alert>
                              <br />
                              <Typography id="tituloEndRetirada">ENDEREÇO PARA RETIRAR</Typography>
                          </Grid>
                          <Grid>
                            <Typography id="endRetirada">{dadosloja.endereco.rua+", "+ dadosloja.endereco.numero+" - "+dadosloja.endereco.bairro+" "+dadosloja.endereco.cidade+"/"+dadosloja.endereco.estado}</Typography>
                          </Grid>
                      </Grid>
                  </TabPanel>                
              </Grid>
              <Divider />
              <Grid style={{textAlign: 'center', marginTop: 30, marginBottom: 30}}>          
                  <Typography id="tituloEndRetirada">FINALIZANDO...</Typography>
                  <Typography id="endRetirada">Agora para enviar seu pedido, basta clicar no botão abaixo</Typography>
                  <br />
                <ColorButton startIcon={<WhatsAppIcon />} type="submit" variant="contained" color="primary">Enviar o pedido via WhatsApp</ColorButton>
              </Grid>           
              </form> 
          </Grid>
          </>
      )
    }else{
      return (
        <Grid id="corpoPedido" style={{ width: telaLargura, marginLeft: posicaoCardapio}}>
          <Grid container justify="space-between" style={{paddingBottom: 10}}>
            <Grid container style={{width: '50%'}} alignItems="center">
              <Grid>
                <ShoppingBasketTwoToneIcon style={{marginRight: 10, marginLeft: 10}} />
              </Grid>
              <Grid>
                <Typography>SUA CESTA</Typography>
              </Grid>
            </Grid>
            <Grid>
              <IconButton onClick={()=>window.history.back()}><ArrowBackTwoToneIcon /></IconButton>
            </Grid>
          </Grid>
          <Divider />
          <Grid container style={{marginTop: 30}} alignContent="center" justify="center" direction="column">
            <Grid item style={{textAlign: 'center'}}>
              <img alt="carrinho vazio" src={require('./imagens/carrinho-vazio.jpg')} />
            </Grid>
            <Grid item>
              <Typography id="textoCestaVazial1">Sua cesta está vazia!</Typography>
              <Typography id="textoCestaVazial2">Volte ao cardápio para acrescenar itens.</Typography>
            </Grid>
          </Grid>
        </Grid>
      )
    }
}

export default Checkout;