import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import './index.css';
import App from './App';
import Home from './Home';
import Erro from './404';
import Checkout from './checkout';
import * as serviceWorker from './serviceWorker';

const dominio = window.location.hostname;
let dominioProprio = false;
sessionStorage.removeItem("dominioProprio");
sessionStorage.removeItem("dominio");
sessionStorage.setItem("dominioProprio", false);
sessionStorage.setItem("dominio", dominio);

if(dominio !== "localhost" && dominio !== "pedezazzz.com.br"){
  dominioProprio = true;
  sessionStorage.setItem("dominioProprio", true);
}

if(dominioProprio){
  ReactDOM.render(
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={App} />
        <Route path="/checkout" exact component={Checkout} />
        <Route path="*" component={Erro} />
      </Switch>
    </BrowserRouter>,
    document.getElementById('root')
  );
}else{
  ReactDOM.render(
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/:user/checkout" exact component={Checkout} />
        <Route path="/:user" exact component={App} />
        <Route path="*" component={Erro} />
      </Switch>
    </BrowserRouter>,
    document.getElementById('root')
  );  
}




// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

/*
  <React.StrictMode>
    <App />
  </React.StrictMode>,
*/